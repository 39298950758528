<template>
  <div :style="this.CSS" :class="CSSClasses" v-show="!isHidden">
    <el-switch
      v-if="loaded"
      v-model="localValue"
      :disabled="_isReadonly"
      :name="name"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      :active-text="_activeText"
      :inactive-text="_inactiveText"
      :active-value="localActiveValue"
      :inactive-value="localInactiveValue"
      :width="_width"
    ></el-switch>
  </div>
</template>

<script>
import Vue from 'vue'
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import { jsonParse } from '../../../../helpers'

export default Vue.extend({
  name: 'a-switch',

  mixins: [mixin, VisibleMixin],

  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },

  props: {
    model: {
      frozen: true
    },

    value: {
      frozen: true
    },

    editorAlias: {
      type: String,
      description: 'alias'
    },

    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },

    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },

    switcherOptions: {
      type: Object,
      default () {
        return {
          activeText: 'Активное значение',
          inactiveText: 'Неактивное значение',
          activeValue: 'true',
          inactiveValue: 'false'
        }
      },
      editor: 'OptionsSwitcher'
    },

    alwaysEnabled: {
      type: Boolean,
      description: 'always_enabled'
    },

    activeText: {
      type: String,
      default: '',
      frozen: true
    },

    inactiveText: {
      type: String,
      default: '',
      frozen: true
    },

    width: {
      type: String,
      description: 'width',
      default: '40px'
    },

    activeColor: {
      type: String,
      description: 'active_color',
      default: '#409EFF',
      editor: 'ColorPicker'
    },

    inactiveColor: {
      type: String,
      description: 'inactive_color',
      default: '#C0CCDA',
      editor: 'ColorPicker'
    }
  },

  data () {
    return {
      localValue: this.value,
      localActiveValue: true,
      localInactiveValue: false,
      loaded: false
    }
  },

  computed: {
    _width () {
      return +this.width || 40
    },

    _activeValue () {
      return this.switcherOptions.activeValue ?? true
    },

    _inactiveValue () {
      return this.switcherOptions.inactiveValue ?? false
    },

    _activeText () {
      return this.switcherOptions.activeText ?? 'true'
    },

    _inactiveText () {
      return this.switcherOptions.inactiveText ?? 'false'
    }
  },

  async mounted () {
    if (this.activeText) {
      this.$set(this.switcherOptions, 'activeText', this.activeText)
      this.$emit('change-property', { name: 'activeText', value: '' })
    }

    if (this.inactiveText) {
      this.$set(this.switcherOptions, 'inactiveText', this.inactiveText)
      this.$emit('change-property', { name: 'inactiveText', value: '' })
    }

    this.localActiveValue = await this.getValueState(this._activeValue, true)
    this.localInactiveValue = await this.getValueState(this._inactiveValue, false)

    // Вызывется перед след условиеим
    this.fixLocalValue()

    // Если включено свойство "всегда активно" (активно по умолчанию)
    if (this.alwaysEnabled) {
      this.localValue = this.localActiveValue
    }

    this.loaded = true
  },

  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },

    value () {
      if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
        this.localValue = this.value

        // Вызывается на случай, если за пределами компонента атрибут меняет значение
        // неподходящее не одному из двух возможных состояний
        this.fixLocalValue()
      }
    },

    localValue () {
      if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
        this.$emit('input', this.localValue)
      }
    }
  },

  methods: {
    /**
     * @param state
     * @param errorValue - занчение, которое будет установлено, если аналитик рукажоп
     *
     * @return {any}
     */
    async getValueState (state, errorValue) {
      state = (state ?? '').trim()

      if (/user.attr_[0-9]+_/i.test(state)) {
        const data = await this.$store.getters['Authorization/userAttributeData'](
          state.match(/attr_[0-9]+_/gi)[0]
        )

        if (data instanceof Object) {
          return errorValue
        }

        return jsonParse(data)
      } else if (state === '{{user_id}}') {
        return this.$store.getters['Authorization/userId']
      } else if (state === '{{role_id}}') {
        return this.$store.getters['Authorization/roleId']
      } else if (state === '') {
        return null
      } else {
        return jsonParse(state)
      }
    },

    fixLocalValue () {
      // Случай, если указали атрибут хер пойми с каким значением
      // Автоматически у атрибута устанавливается знчение в неактивном состоянии
      if (this.localValue !== this.localActiveValue && this.localValue !== this.localInactiveValue) {
        this.localValue = this.localInactiveValue
      }
    }
  }
})
</script>
